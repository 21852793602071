<template>
  <div class="cy_brand">
    <div class="cy_brand_bg" v-if="Object.keys(basicData).length != 0">
      <img :src="basicData.bgImg" alt="" style="width:100%" />
    </div>
    <ul class="cy_brand_head">
      <li
        v-for="(item, idx) in basicData.basicList"
        :key="idx"
        :class="[active == idx ? 'li_on' : '']"
        @click="active = idx"
      >
        {{ item.value }}
      </li>
      <li class="li_zxsq">在线申请</li>
    </ul>
    <div class="cy_brand_nav" v-if="active == 0" style="margin-bottom: 0">
      <h2 class="nav_jmtj_title">
        {{ jmtjData.tjTitle }}
      </h2>
      <ul class="nav_jmtj_list">
        <li v-for="(item, idx) in jmtjData.jmtjList" :key="idx">
          <div class="li_img">
            <img
              :src="jmtjData.jmtjImg[idx]"
              alt=""
              style="width:100%;height:100%"
            />
          </div>
          <h4>{{ item.key }}</h4>
          <p>{{ item.value }}</p>
        </li>
      </ul>
      <div class="nav_jmtj_sqfs">
        <div class="sqfs_nav">
          <h2 class="nav_jmtj_title">{{ jmtjData.sqfs }}</h2>
          <div class="sqfs_nav_nr">
            <div class="sqfs_nav_nr_pic">
              <img :src="jmtjData.jmtjFs" alt="" style="width: 100%;" />
            </div>
            <div class="sqfs_nav_nr_txt">
              <ul>
                <li v-for="(item, idx) in jmtjData.jmtjList2" :key="idx">
                  <span
                    ><b>{{ item.value }}</b
                    >{{ idx != 2 ? '万元' : '%' }}</span
                  >{{ item.key }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cy_brand_nav" v-if="active == 1">
      <div class="nav_jmlc_img">
        <img
          :src="jmlcData.lcImg[0]"
          alt=""
          class="img1"
          style="width:100%;height:100%"
        />
        <img
          :src="jmlcData.lcImg[1]"
          alt=""
          class="img2"
          style="width:100%;height:100%;display:none"
        />
      </div>
    </div>
    <div class="cy_brand_nav" v-if="active == 2">
      <ul class="nav_jmzc_list">
        <li v-for="(item, idx) in jmzcData.jmzcIist" :key="idx">
          <div class="li_nav" v-if="idx == 0 || idx == 2">
            <div class="li_nav_pic">
              <img
                :src="jmzcData.jmzcImg[idx]"
                alt=""
                style="width:100%;height:100%"
              />
            </div>
            <div class="li_nav_txt">
              <h2 class="nav_jmtj_title" style="font-size: 24px;margin:0">
                {{ item.key }}
              </h2>
              <p>{{ item.value }}</p>
            </div>
          </div>
          <div class="li_nav" v-if="idx == 1 || idx == 3">
            <div class="li_nav_txt li_nav_txt1">
              <h2 class="nav_jmtj_title" style="font-size: 24px;margin:0">
                {{ item.key }}
              </h2>
              <p>{{ item.value }}</p>
            </div>
            <div class="li_nav_pic">
              <img :src="jmzcData.jmzcImg[idx]" alt="" style="width:100%" />
            </div>
            <div class="li_nav_txt li_nav_txt2" style="display:none">
              <h2 class="nav_jmtj_title" style="font-size: 24px;margin:0">
                {{ item.key }}
              </h2>
              <p>{{ item.value }}</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="cy_brand_nav" v-if="active == 3">
      <div
        class="nav_yzfx_list"
        :style="{
          background: `url(${yzfxData.yzfxImg}) top center no-repeat #fff`
        }"
      >
        <ul>
          <li v-for="(item, idx) in yzfxData.yzfxData" :key="idx">
            <div class="li_box">
              <img :src="yzfxData.yzfxList[idx]" alt="" style="width:100%" />
              <img
                :src="
                  JSON.parse(item.value)
                    ? yzfxData.yzfxKg[1]
                    : yzfxData.yzfxKg[0]
                "
                alt=""
                class="open"
                @click="item.value = !JSON.parse(item.value)"
              />
            </div>
            <h2 class="li_title">{{ yzfxData.yzfxData[idx].key }}</h2>
            <div class="li_text" v-if="JSON.parse(item.value)">
              <h2>{{ yzfxData.yzfxData2[idx].key }}</h2>
              <p>{{ yzfxData.yzfxData2[idx].value }}</p>
              <div
                class="li_text_close"
                @click="item.value = !JSON.parse(item.value)"
              >
                CLOSE
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { getPageMsg } from '@/api/api'
export default {
  components: {},
  computed: {},
  data () {
    return {
      active: 0,
      basicData: {},
      jmtjData: {},
      jmlcData: {},
      jmzcData: {},
      yzfxData: {}
    }
  },
  watch: {},
  methods: {
    getData () {
      let that = this
      let id = sessionStorage.getItem(that.changeData() + 'id')
      getPageMsg(id).then(res => {
        if (res.data.code == 1) {
          console.log(res.data.data)
          that.basicData = res.data.data.basic
          that.jmtjData = res.data.data.加盟条件
          that.jmlcData = res.data.data.加盟流程
          that.jmzcData = res.data.data.加盟支持
          that.yzfxData = res.data.data.优质分享
          that.yzfxData.yzfxData.forEach(el => {
            if (el) {
              el.show = false
            }
          })
        }
      })
    },
    toLink (i) {
      sessionStorage.setItem(this.changeData() + 'id', i)
    }
  },
  mounted () {
    this.getData()
  }
}
</script>

<style lang="less" scoped>
@import url('~@/assets/css/mixins.less'); //混合
.cy_brand {
  width: 100%;
  height: 100%;
  color: #212529;
  .cy_brand_head {
    width: 100%;
    border-bottom: 1px solid #d0d0d0;
    white-space: nowrap;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    li {
      margin: 0 20px;
      font-size: 18px;
      padding: 30px 0;
      box-sizing: border-box;
      cursor: pointer;
    }
    .li_zxsq {
      color: #191919;
      background-color: #fabe0d;
      border-radius: 20px;
      height: 38px;
      line-height: 38px;
      padding: 0 20px;
      font-weight: 500;
      border-bottom: 3px;
    }
    .li_on {
      border-bottom: 3px solid #c8151d;
      font-weight: bold;
    }
  }
  .cy_brand_nav {
    max-width: 1200px;
    margin: 80px auto;
    .nav_jmtj_title {
      font-size: 32px;
      text-align: center;
      margin: 50px 0 80px 0;
    }
    .nav_jmtj_title:after {
      content: '';
      width: 30px;
      height: 4px;
      display: block;
      margin: 0 auto;
      background: #fabe0d;
      margin-top: 15px;
    }
    .nav_jmtj_list {
      display: flex;
      flex-wrap: wrap;
      li {
        width: calc(25% - 20px);
        box-sizing: border-box;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
        border-radius: 6px;
        padding: 0 25px;
        height: 400px;
        font-size: 14px;
        color: #555;
        margin: 0 10px;
        .li_img {
          width: 100px;
          margin: 70px auto 45px auto;
        }
        h4 {
          font-size: 18px;
          text-align: center;
          color: #191919;
          margin-bottom: 20px;
        }
        p {
          line-height: 2;
        }
      }
    }
    .nav_jmtj_sqfs {
      width: 180%;
      padding: 80px 0;
      background: #332c2a;
      margin-top: 90px;
      overflow: hidden;
      margin-left: -40%;
      .sqfs_nav {
        width: 1200px;
        margin: 0 auto;
        h2 {
          font-size: 32px;
          text-align: center;
          color: #fff;
          line-height: 34px;
          margin: 0 0 75px 0;
        }
        .sqfs_nav_nr {
          width: 100%;
          position: relative;
          .sqfs_nav_nr_pic {
            width: 47.3%;
            position: absolute;
            left: 0;
            top: 0;
          }
          .sqfs_nav_nr_txt {
            width: 98%;
            box-sizing: border-box;
            border: 1px solid #f9bd0d;
            padding: 0 30px 15px 0;
            float: right;
            margin-top: 15px;
            min-height: 375px;
            ul {
              width: 47%;
              float: right;
              color: #fff;
              font-size: 16px;
              li {
                margin-top: 35px;
                font-size: 14px;
                span {
                  color: #f9bd0d;
                  margin-right: 12px;
                  b {
                    font-size: 42px;
                    font-weight: 200;
                    margin-right: 5px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .nav_jmzc_list {
      li {
        width: 100%;
        .li_nav {
          display: flex;
          .li_nav_pic {
            width: 50%;
            height: 350px;
          }
          .li_nav_txt {
            width: calc(50% - 100px);
            height: 350px;
            padding: 0 50px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            background: #f2f2f2;
            p {
              line-height: 1.5;
              margin-top: 20px;
            }
          }
        }
      }
    }
    .nav_yzfx_list {
      background-size: 100% auto !important;
      box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.07);
      border-radius: 2px;
      padding: 40px 12.04%;
      box-sizing: border-box;
      ul li {
        margin-bottom: 50px;
        .li_box {
          width: 170px;
          height: 170px;
          position: relative;
          margin: 0 auto;
          .open {
            width: 65px;
            position: absolute;
            bottom: 0;
            right: 0;
            cursor: pointer;
          }
        }
        .li_title {
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #222222;
          line-height: 28px;
          margin: 20px auto;
          text-align: center;
        }
        .li_text {
          h2 {
            text-align: center;
            color: #222222;
            margin: 20px 0 30px;
          }
          p {
            line-height: 1.8;
            text-indent: 2em;
          }
          .li_text_close {
            font-size: 20px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #222222;
            text-align: center;
            text-decoration: underline;
            margin-top: 30px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
